import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {FieldOptionsServiceService} from '../workarea/shared/field-options-service.service';
import {Storage} from '../storage.class';
import {Filter} from '../filter';
import {TownProjectService} from '../services/townproject/town-project.service';
import {TownProject} from '../town-project';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Town} from '../town.class';
import {Status} from '../status.class';
import {WorkareaService} from '../services/workarea/workarea.service';

@Component({
    selector: 'app-workareas-filter',
    templateUrl: './workareas-filter.component.html',
    styleUrls: ['./workareas-filter.component.scss']
})
export class WorkareasFilterComponent implements OnInit, OnDestroy {

    public fieldOptions = {};
    public filter = new Filter();
    public filterChange = new EventEmitter();
    public closeFilter = new EventEmitter();
    projects: TownProject[];
    @Input() town: Town;
    private subscriptions = new Subscription();
    statuses: Status[];

    constructor(private fieldOptionService: FieldOptionsServiceService,
                private townProjectService: TownProjectService,
                private workareaService: WorkareaService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.workareaService.getAvailableStatuses(this.town.towntype).then(statuses => {
            this.statuses = statuses;
        });
        this.filter = new Filter();
        this.subscriptions.add(this.activatedRoute.queryParams.pipe(startWith(this.activatedRoute.snapshot.queryParams)).subscribe(params => {
            if (Object.keys(params).length > 0) {
                if (!!params['resetfilter']) {
                    this.filter = new Filter();
                    this.inputChanged();
                } else if (JSON.stringify(params) !== JSON.stringify(this.filter.forParams())) {
                    const newParams = JSON.parse(JSON.stringify(params));
                    newParams.startDate = newParams.startDate ? new Date(+newParams.startDate) : null;
                    newParams.endDate = newParams.endDate ? new Date(+newParams.endDate) : null;
                    delete newParams['movePushpin'];
                    delete newParams['newLat'];
                    delete newParams['newLng'];
                    newParams.statuses = newParams.statuses?.split(',').filter(s => !!s).map(s => +s) ?? [];
                    Object.assign(this.filter, newParams);
                    this.filter.project = this.filter.project ? +this.filter.project : null;
                    if (this.filter.klinkerfloor !== null) {
                        this.filter.klinkerfloor = (this.filter.klinkerfloor === 'true' || this.filter.klinkerfloor === true);
                    }
                    this.filter.min = this.filter.min ? +this.filter.min : null;
                    this.filter.max = this.filter.max ? +this.filter.max : null;
                    Storage.setFilter(this.filter);
                }
            } else {
                const storageFilter = Storage.getFilter();
                delete storageFilter['movePushpin'];
                delete storageFilter['newLat'];
                delete storageFilter['newLng'];
                if (storageFilter) {
                    Object.assign(this.filter, storageFilter);
                    this.inputChanged();
                }
            }
        }));

        this.filterChange.emit(this.filter);
        this.fieldOptionService.getOptions().then(options => {
            this.fieldOptions = options;
        });
        this.townProjectService.getProjects(false, this.town.id).subscribe(projects => {
            this.projects = projects.data;
        });
    }

    inputChanged() {
        Storage.setFilter(this.filter);
        this.router.navigate([], {
            queryParams: this.filter.forParams(),
            queryParamsHandling: 'merge'
        });
        this.filterChange.emit(this.filter);
    }

    applyFilterButton() {
        this.inputChanged();
        this.closeFilter.emit();
    }

    resetFilter() {
        this.filter = new Filter();
        this.router.navigate([], {
            queryParams: this.filter.forParams(),
            queryParamsHandling: 'merge'
        });
        this.inputChanged();
    }
}
